import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import ADDNewProduct from "./Product/ADDNewProduct/ADDNewProduct"
import DeleteProduct from "./Product/Delete/Delete"
import EditProduct from "./Product/Edit/Edit"
//

import ProductService from '../services/product';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'perTime', label: 'Per Time', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Project() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [products,setProducts] = useState([])
  const [process,setProcess] = useState({
    product:{features:[""]},
    edit:false,
    del:false,
    addNewProduct:false
  })
  const [alert,setAlert] = useState({
    open:false,
    color:'success',
    msg:''

  })

  const [loading,setLoading] = useState(false)

  const getAll = async()=>{
    const res = await ProductService.getAll()
    setProducts(res)
  }

  useEffect(()=>{
    getAll()
  },[])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = products.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([])
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const addNewProduct= ()=>{
    setProcess({product:{features:[""]},edit:false,del:false,addNewProduct:true})
  }

  const deleteSelected = ()=>{
    setLoading(true)
    ProductService.delMany(selected).then(res=>{
      if(res.data.error){
        setAlert({
          open:true,
          color:'error',
          msg:res.data.error
        })
      }
      else{
        setSelected([])
        getAll()
        setAlert({
          open:true,
          color:'success',
          msg:(res.data.deletedCount+" products deleted")
        })
      }
      setLoading(false)
    })
  }
  

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

  const filteredUsers = applySortFilter(products, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Product">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={loading}
        >
            <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{vertical:'top', horizontal:'center'}}
        open={alert.open}
        onClose={()=>setAlert({
          open:false,
          color:'success',
          msg:''
        })}
      >
        <Alert variant="filled" severity={alert.color}>{alert.msg}</Alert>
      </Snackbar>
      <ADDNewProduct open={process.addNewProduct} setProducts={setProducts} setAlert={setAlert} CloseDialog={()=>setProcess({product:{features:[""]},edit:false,del:false,addNewProduct:false})}/>
      <DeleteProduct open={process.del} product={process.product} setProducts={setProducts} setAlert={setAlert} CloseDialog={()=>setProcess({product:{features:[""]},edit:false,del:false,addNewProduct:false})}/>
      <EditProduct open={process.edit} prod={process.product} setProducts={setProducts} setAlert={setAlert} CloseDialog={()=>setProcess({product:{features:[""]},edit:false,del:false,addNewProduct:false})}/>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Product
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={addNewProduct}
          >
            New Product
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            DeleteSelected={deleteSelected}
            placeholder="Search product..."
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={products.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { _id, title, price,description,perTime, status } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, _id)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {title}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{price}</TableCell>
                          <TableCell align="left">{description}</TableCell>
                          <TableCell align="left">{perTime}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={status === false ? 'error':'success'}
                            >
                              {status === false ? 'inactive':'active'}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <UserMoreMenu del={()=>setProcess({product:row,edit:false,del:true,addNewProduct:false})} edit={()=>setProcess({product:row,edit:true,del:false,addNewProduct:false})} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

import React,{useEffect, useState} from "react"
import { Icon } from '@iconify/react';
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {TextField,Box,Switch,FormControlLabel,Button,CircularProgress,Backdrop,IconButton} from '@mui/material'
import trash2Fill from '@iconify/icons-eva/trash-2-fill';

import ProductService from "../../../services/product"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  

const Edit = ({open,CloseDialog,setAlert,setProducts,prod})=>{

    const [product,setProduct] = useState({
        title:"",
        price:"",
        description:"",
        perTime:"",
        features:[""],
        status:true
    })
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        setProduct(prod)
    },[prod])

    const handleChange = (e,index)=>{
        const {name,value} = e.target

        setProduct(prevValue=>{
            if(name === "title"){
                return{...prevValue,title:value}
            }
            else if(name === "price"){
                return{...prevValue,price:value}
            }
            else if(name === "description"){
                return{...prevValue,description:value}
            }
            else if(name === "perTime"){
                return{...prevValue,perTime:value}
            }
            else if(name === "status"){
                return{...prevValue,status:!prevValue.status}
            }
            else if(name === "feature"){
                const temp = prevValue.features
                temp[index] = value
                return{...prevValue,features:temp}
            }
        })
    }

    const update = (e)=>{
        e.preventDefault()
        setLoading(true)
        ProductService.update(product).then(res=>{
            console.log(res.data)
            if(res.data.error){
                setAlert({
                    open:true,
                    color:'error',
                    msg:res.data.error
                  })
            }
            else{
                setProducts(prevValue=>{
                    return prevValue.map(i=>{
                        if(i._id === product._id){
                            return res.data
                        }
                        else{
                            return i
                        }
                    })
                })
                setAlert({
                    open:true,
                    color:'success',
                    msg:'Product saved'
                  })
            }
            setLoading(false)
            CloseDialog()
        })
    }

    return(<Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>{
            setProduct({
                title:"",
                price:"",
                description:"",
                perTime:"",
                features:[""],
                status:true
            })
            CloseDialog()
        }}
        className="payment-dialog"
      >
        <div className="payment">
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
            open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <h1 className="payment-price text-center">Edit Project</h1>  
            <form onSubmit={update}>
                <Box
                sx={{
                    '& > :not(style)': { m: 1, width: '45ch' },
                }}
                noValidate
                autoComplete="off"
                >
                    <TextField id="standard-basic" label="Title" variant="standard" name="title" onChange={handleChange} value={product.title} InputLabelProps={{ shrink: true }} />
                    <TextField id="standard-basic" label="Price" variant="standard" name="price" onChange={handleChange} value={product.price} InputLabelProps={{ shrink: true }} />
                    <TextField id="standard-basic" label="Description" variant="standard" name="description" onChange={handleChange} value={product.description} InputLabelProps={{ shrink: true }}/>
                    <TextField id="standard-basic" label="Per Time" variant="standard" name="perTime" onChange={handleChange} value={product.perTime} InputLabelProps={{ shrink: true }}/>
                    <h5>Features</h5>
                    {product.features.map((item,index)=>(
                        <div key={index}>
                            <TextField style={{width:"90%"}} id="standard-basic" label={"feature "+(index+1)} variant="standard" name="feature" onChange={(e)=>handleChange(e,index)} value={product.features[index]} InputLabelProps={{ shrink: true }}/>
                            <IconButton color="error" onClick={()=>{
                                setProduct(prevValue=>({...prevValue,features:prevValue.features.filter((it,ind)=>ind!== index)}))
                            }}>
                                <Icon icon={trash2Fill} />
                            </IconButton>
                        </div>
                    ))}
                    <Button variant="contained" onClick={()=>setProduct(prevValue=>({...prevValue,features:[...prevValue.features,""]}))}>+</Button>
                </Box>
                <FormControlLabel style={{marginTop:"1em"}} control={<Switch checked={product.status} onChange={handleChange} name="status"/>} label="Status" />
                <Button variant="contained" type="submit" className="Btn">Save</Button>
            </form>
        </div>
      </Dialog>)
}

export default Edit
import React,{useEffect, useState} from "react"
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {TextField,Box,Switch,FormControlLabel,Button,CircularProgress,Backdrop} from '@mui/material'

import ProjectService from "../../../services/project"

import "./Edit.css"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  

const Edit = ({open,CloseDialog,setAlert,setProjects,proj})=>{

    const [project,setProject] = useState({
        title:"",
        href:"",
        status:false
    })
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        setProject(proj)
    },[proj])

    const handleChange = (e)=>{
        const {name,value} = e.target

        setProject(prevValue=>{
            if(name === "title"){
                return{...prevValue,title:value}
            }
            else if(name === "href"){
                return{...prevValue,href:value}
            }
            else if(name === "status"){
                return{...prevValue,status:!prevValue.status}
            }
        })
    }

    const update = (e)=>{
        e.preventDefault()
        setLoading(true)
        ProjectService.update(project).then(res=>{
            console.log(res.data)
            if(res.data.error){
                setAlert({
                    open:true,
                    color:'error',
                    msg:res.data.error
                  })
            }
            else{
                setProjects(prevValue=>{
                    return prevValue.map(i=>{
                        if(i._id === project._id){
                            return res.data
                        }
                        else{
                            return i
                        }
                    })
                })
                setAlert({
                    open:true,
                    color:'success',
                    msg:'Project saved'
                  })
            }
            setLoading(false)
            CloseDialog()
        })
    }

    return(<Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={CloseDialog}
        className="payment-dialog"
      >
        <div className="payment">
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
            open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <h1 className="payment-price text-center">Edit Project</h1>  
            <form onSubmit={update}>
                <Box
                sx={{
                    '& > :not(style)': { m: 1, width: '45ch' },
                }}
                noValidate
                autoComplete="off"
                >
                    <TextField id="standard-basic" label="Title" variant="standard" name="title" onChange={handleChange} value={project.title} InputLabelProps={{ shrink: true }} />
                    <TextField id="standard-basic" label="Href" variant="standard" name="href" onChange={handleChange} value={project.href} InputLabelProps={{ shrink: true }} />
                </Box>
                <FormControlLabel style={{marginTop:"1em"}} control={<Switch checked={project.status} onChange={handleChange} name="status"/>} label="Status" />
                <Button variant="contained" type="submit" className="Btn">Save</Button>
            </form>
        </div>
      </Dialog>)
}

export default Edit
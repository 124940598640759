import React, { useEffect, useState } from "react"
import {useLocation,useNavigate} from "react-router-dom"

// material
import {
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
    Pagination,
    Backdrop,
    CircularProgress,
    Snackbar,
    Alert
} from '@mui/material';

// components
import Page from '../components/Page';

import ContactService from "../services/contact"

export default function Contact() {

    let location = useLocation();
    let navigate = useNavigate();
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(2);
    const [contacts,setContact] = useState([])
    const [loading,setLoading] = useState(false)
    const [alert,setAlert] = useState({
        open:false,
        color:'success',
        msg:''
      })
    const getContacts = (page)=>{
        ContactService.getWithPagination(page).then(res=>{
            setCount(res.len%5===0?parseInt(res.len/5):parseInt(res.len/5)+1)
            setContact(res.results)
        })
    }

    useEffect(()=>{
        const pag = location.search.replace("?page=","")
        if(!pag){
            setPage(1)
        }
        else{
            setPage(Number(pag))
        }
        getContacts(pag)
    },[location])

    const handleChange = (event, value) => {
        navigate("/dashboard/contact?page="+value)
    };

    const del = (id)=>{
        setLoading(true)
        ContactService.del({_id:id}).then(res=>{
            if(res.data.error){
                setAlert({
                  open:true,
                  color:'error',
                  msg:res.data.error
                })
            }
            else{
                const pag = location.search.replace("?page=","")
                getContacts(pag)
                setAlert({
                    open:true,
                    color:'success',
                    msg:'Contact deleted'
                })
            }
        })
        setLoading(false)
    }

  return (
    <Page title="Contact">
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
        anchorOrigin={{vertical:'top', horizontal:'center'}}
        open={alert.open}
        onClose={()=>setAlert({
          open:false,
          color:'success',
          msg:''
        })}
        >
            <Alert variant="filled" severity={alert.color}>{alert.msg}</Alert>
        </Snackbar>
        {contacts.length === 0 &&<h1 style={{padding:"1em"}}>Not found</h1>}
        {contacts.map(i=>(<Card key={i._id} sx={{ minWidth: 275 }} style={{marginBottom:"1em"}}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Contact Message
                </Typography>
                <Typography variant="h5" component="div">
                {i.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {i.email}
                </Typography>
                <Typography variant="body2">
                {i.message}
                </Typography>
            </CardContent>
            <CardActions>
                <Button style={{marginLeft:".5em"}} size="small" variant="contained" color="error" onClick={()=>del(i._id)}>Delete</Button>
            </CardActions>
        </Card>))}
        <Pagination style={{position:"relative",left:"40%"}} count={count} color="success" page={page} onChange={handleChange} />
    </Page>
  );
}

import axios from 'axios';
import getCookie from 'src/utils/getCookie';

const url = process.env.REACT_APP_API_URL + 'contact/'

const getWithPagination = async (page) => {
      const res = await axios.get(url + 'getWithPagination?page='+page+"&token="+getCookie('token'));
      return res.data;
};

const getLength = async () => {
      const res = await axios.get(url + 'getLength?token='+getCookie('token'));
      return res.data;
};

const del = async (project) => {
      return axios.post(url + 'del',{...project,token:getCookie('token')});
};

const service = { getLength,getWithPagination ,del };

export default service;

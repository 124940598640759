import React, { useState } from "react"

// material
import {
    Grid,
    Typography,
    Card,
    Container,
    Box,
    Snackbar,
    Alert
} from '@mui/material';
import { Icon } from '@iconify/react';
import commentIcon from '@iconify/icons-ant-design/comment-outlined';
import { alpha, styled } from '@mui/material/styles';

// components
import Page from '../components/Page';
import SendMsg from "./Admin/sendMsg"

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    color: theme.palette.info.darker,
    backgroundColor: theme.palette.info.lighter
  }));
  
  const IconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.info.dark,
    backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
      theme.palette.info.dark,
      0.24
    )} 100%)`
  }));

export default function Admin() {

    const [process,setProcess] = useState({
        sendMsg:false
      })
    const [alert,setAlert] = useState({
        open:false,
        color:'success',
        msg:''
      })


    return (
    <Page title="Admin">
        <Snackbar
        anchorOrigin={{vertical:'top', horizontal:'center'}}
        open={alert.open}
        onClose={()=>setAlert({
          open:false,
          color:'success',
          msg:''
        })}
        >
            <Alert variant="filled" severity={alert.color}>{alert.msg}</Alert>
        </Snackbar>
        <SendMsg open={process.sendMsg} setAlert={setAlert} CloseDialog={()=>setProcess({sendMsg:false})}/>
        <Container maxWidth="xl">
            <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Admin</Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                <RootStyle onClick={()=>setProcess({sendMsg:true})} style={{cursor:'pointer'}}>
                    <IconWrapperStyle>
                        <Icon icon={commentIcon} width={24} height={24} />
                    </IconWrapperStyle>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                        Send Message
                    </Typography>
                </RootStyle>
                </Grid>
            </Grid>
        </Container>
    </Page>
  );
}

import React,{useState} from "react"
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {TextField,Box,Switch,FormControlLabel,Button,CircularProgress,Backdrop} from '@mui/material'

import ProjectService from "../../../services/project"

import "./ADDNewProject.css"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  

const ADDNewProject = ({open,CloseDialog,setAlert,setProjects})=>{

    const [project,setProject] = useState({
        title:"",
        href:"",
        status:true
    })
    const [loading,setLoading] = useState(false)

    const handleChange = (e)=>{
        const {name,value} = e.target

        setProject(prevValue=>{
            if(name === "title"){
                return{...prevValue,title:value}
            }
            else if(name === "href"){
                return{...prevValue,href:value}
            }
            else if(name === "status"){
                return{...prevValue,status:!prevValue.status}
            }
        })
    }

    const add = (e)=>{
        e.preventDefault()
        setLoading(true)
        ProjectService.add(project).then(res=>{
            console.log(res.data)
            if(res.data.error){
                setAlert({
                    open:true,
                    color:'error',
                    msg:res.data.error
                  })
            }
            else{
                setProjects(prevValue=>[...prevValue,res.data])
                setAlert({
                    open:true,
                    color:'success',
                    msg:'Project added'
                  })
            }
            setLoading(false)
            CloseDialog()
            setProject({
                title:"",
                href:"",
                status:true
            })
        })
    }

    return(<Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={CloseDialog}
        className="payment-dialog"
      >
        <div className="payment">
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
            open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <h1 className="payment-price text-center">Add new Project</h1>  
            <form onSubmit={add}>
                <Box
                sx={{
                    '& > :not(style)': { m: 1, width: '45ch' },
                }}
                noValidate
                autoComplete="off"
                >
                    <TextField id="standard-basic" label="Title" variant="standard" name="title" onChange={handleChange} value={project.title}/>
                    <TextField id="standard-basic" label="Href" variant="standard" name="href" onChange={handleChange} value={project.href}/>
                </Box>
                <FormControlLabel style={{marginTop:"1em"}} control={<Switch checked={project.status} onChange={handleChange} name="status"/>} label="Status" />
                <Button variant="contained" type="submit" className="Btn">Add</Button>
            </form>
        </div>
      </Dialog>)
}

export default ADDNewProject
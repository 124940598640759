import axios from 'axios';
import getCookie from 'src/utils/getCookie';


const url = process.env.REACT_APP_API_URL+'auth/'

const token = async (user) => {
    
    return axios.post(url + 'token',user);
};

const auth = async () => {
    return axios.post(url + 'auth',{token:getCookie('token')});
};

const service = { token , auth };

export default service;

import axios from 'axios';
import getCookie from 'src/utils/getCookie';

const url = process.env.REACT_APP_API_URL + 'project/';

const getAll = async () => {
      const res = await axios.get(url + 'getall');
      return res.data;
};

const getLength = async () => {
      const res = await axios.get(url + 'getLength');
      return res.data;
};

const add = async (project) => {
      return axios.post(url + 'add',{...project,token:getCookie('token')});
};

const update = async (project) => {
      return axios.post(url + 'update',{...project,token:getCookie('token')});
};

const del = async (project) => {
      return axios.post(url + 'del',{...project,token:getCookie('token')});
};

const delMany = async (projects) => {
      return axios.post(url + 'delMany',{projects:projects,token:getCookie('token')});
};

const service = { getAll,getLength,add,del,update,delMany };

export default service;

import axios from 'axios';
import getCookie from 'src/utils/getCookie';

const url = process.env.REACT_APP_API_URL + 'order/'

const getLength = async () => {
      const res = await axios.get(url + 'getLength?token='+getCookie('token'));
      return res.data;
};
const service = { getLength };

export default service;

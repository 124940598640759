import React,{useState} from "react"
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {DialogContent,DialogActions,DialogContentText,DialogTitle,Button,CircularProgress,Backdrop} from '@mui/material'

import ProductService from "../../../services/product"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({open,CloseDialog,setAlert,setProducts,product}) {

    const [loading,setLoading] = useState(false)

    const delProduct = ()=>{
      setLoading(true)
      ProductService.del(product).then(res=>{
        if(res.data.error){
          setAlert({
            open:true,
            color:'error',
            msg:res.data.error
          })
        }
        else{
          setProducts(prevValue=>{
            const temp = prevValue.filter(i=>i._id !== product._id)
            return temp
          })
          setAlert({
            open:true,
            color:'success',
            msg:'Product deleted'
          })
        }
        setLoading(false)
        CloseDialog()
      })
    }

    return (
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={CloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>{"Are you sure to Delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Title:{product.title}<br/>
            Price:{product.price}<br/>
            Status:{product.status === false ? 'inactive':'active'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={CloseDialog}>Cancel</Button>
          <Button onClick={delProduct}>Yes</Button>
        </DialogActions>
    </Dialog>
  );
}

// material
import { Box, Grid, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
// components
import Page from '../components/Page';
import {
  Contacts,
  Orders,
  Products,
  Projects,
  AppCurrentVisits,
  AppWebsiteVisits,
  Entrants,
} from '../components/_dashboard/app';

//services
import ProductService from "../services/product"
import ProjectService from "../services/project"
import ContactService from "../services/contact"
import OrderService from "../services/order"
import TrafficService from "../services/traffic"

// ----------------------------------------------------------------------

export default function DashboardApp() {

  const [totals,setTotals] = useState({
    projects:0,
    contacts:0,
    products:0,
    orders:0,
    traffic:{},
  })

  const getLengths = async ()=>{
    const products = await ProductService.getLength()
    const contacts = await ContactService.getLength()
    const projects = await ProjectService.getLength()
    const orders = await OrderService.getLength()
    const traffic = await TrafficService.get()
    setTotals({
      products:products.len || 0,
      contacts:contacts.len || 0,
      projects:projects.len || 0,
      orders: orders.len || 0,
      traffic:traffic || {}
    })
  }

  useEffect(()=>{
    getLengths()
  },[])
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Projects TOTAL={totals.projects} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Contacts TOTAL={totals.contacts} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Products TOTAL={totals.products} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Orders TOTAL={totals.orders} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits logins={totals.traffic.logins || {}} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits country={totals.traffic.country || {}} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Entrants lastLogins={totals.traffic.lastLogins || []} />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
}

import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <Box component="img" src="https://avatars.githubusercontent.com/u/47576905?v=4" sx={{ borderRadius:"50%",width: 40, height: 40, ...sx }} />;
}

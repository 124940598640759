import axios from 'axios';
import getCookie from 'src/utils/getCookie';

const url = process.env.REACT_APP_API_URL + 'product/';

const getAll = async () => {
      const res = await axios.get(url + 'getall');
      return res.data;
};

const getLength = async () => {
      const res = await axios.get(url + 'getLength');
      return res.data;
};

const add = async (product) => {
      return axios.post(url + 'add',{...product,token:getCookie('token')});
};

const update = async (product) => {
      return axios.post(url + 'update',{...product,token:getCookie('token')});
};

const del = async (product) => {
      return axios.post(url + 'del',{...product,token:getCookie('token')});
};

const delMany = async (products) => {
      return axios.post(url + 'delMany',{products:products,token:getCookie('token')});
};

const service = { getAll,getLength,add,del,update,delMany };

export default service;

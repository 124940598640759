import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import mdi from '@iconify/icons-ant-design/container-filled';
import server from '@iconify/icons-ant-design/cloud-server-outlined';
import contact from '@iconify/icons-ant-design/contacts';
import bookmark from '@iconify/icons-eva/bookmark-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'project',
    path: '/dashboard/project',
    icon: getIcon(mdi)
  },
  {
    title: 'contact',
    path: '/dashboard/contact',
    icon: getIcon(contact)
  },
  {
    title: 'product',
    path: '/dashboard/product',
    icon: getIcon(bookmark)
  },
  {
    title: 'admin',
    path: '/dashboard/admin',
    icon: getIcon(server)
  }
];

export default sidebarConfig;

import axios from 'axios';
import getCookie from 'src/utils/getCookie';

const url = process.env.REACT_APP_API_URL + 'traffic/';

const get = async () => {
      const res = await axios.get(url + 'get?token='+getCookie('token'));
      return res.data;
};


const service = { get };

export default service;

import React,{useEffect, useState} from "react"
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {TextField,Box,Button,CircularProgress,Backdrop} from '@mui/material'

import {socket} from "../../socket"
import getCookie from "src/utils/getCookie";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  

const SendMsg = ({open,CloseDialog,setAlert})=>{

    const [msg,setMsg] = useState("")
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        socket.on("sendMsg",(data)=>{
            if(!data){
                setAlert({
                    open:true,
                    color:'error',
                    msg:"error"
                  })
            }
            else{
                setAlert({
                    open:true,
                    color:'success',
                    msg:'Message sent'
                  })
            }
            setLoading(false)
            CloseDialog()
            setMsg("")
        })
    },[CloseDialog,setAlert])

    const handleChange = (e)=>{
        const {value} = e.target

        setMsg(value)
    }

    const add = (e)=>{
        e.preventDefault()
        setLoading(true)
        socket.emit("sendMsg",{msg,token:getCookie('token')})
    }

    return(<Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={CloseDialog}
        className="payment-dialog"
      >
        <div className="payment">
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
            open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <h1 className="payment-price text-center">Send Message</h1>  
            <form onSubmit={add}>
                <Box
                sx={{
                    '& > :not(style)': { m: 1, width: '45ch' },
                }}
                noValidate
                autoComplete="off"
                >
                    <TextField id="standard-basic" label="Message" variant="standard" name="title" onChange={handleChange} value={msg}/>
                </Box>
                <Button variant="contained" type="submit" className="Btn">Send</Button>
            </form>
        </div>
      </Dialog>)
}

export default SendMsg